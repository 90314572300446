import React, { useState } from 'react'
import styled from 'styled-components'
import { Loading } from '../../molecules/Budget'
import TransactionalCreditForm from '../../organisms/TransactionalCredit/TransactionalCreditForm'
import TransactionalCreditResult from '../../organisms/TransactionalCredit/TransactionalCreditResult'
import { bffParceirosApiService } from '../../services/bff'
import { CreateTransactionalCreditEvaluationReq } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { PhoneLayout } from '../../ui/PhoneLayout'
import TransactionalCreditHelper from './TransactionalCreditHelper'
import { TransactionalCreditPages } from './TransactionalCreditPages'

const unknownErrorMessage = 'Erro inesperado, por favor, tente novamente!'

const TransactionalCredit: React.FC = () => {
  const [page, setPage] = useState<TransactionalCreditPages>(TransactionalCreditPages.Form)
  const [isRunningPreChecks, setIsRunningPreChecks] = useState<boolean>(false)

  const {
    error,
    isLoading,
    mutate,
    data,
  } = bffParceirosApiService.transactionalCredit.useCreateTransactionalCreditEvaluation()

  React.useEffect(() => {
    if (!!data) setPage(TransactionalCreditPages.Result)
  }, [data])

  React.useEffect(() => {
    if (!!error) alert(error.message)
  }, [error])

  const handleTxCreditEvaluationCreation = async (request: CreateTransactionalCreditEvaluationReq) => {
    if (!request.storeId) {
      return
    }

    setIsRunningPreChecks(true)
    await TransactionalCreditHelper.runPreChecks(request.document, request.storeId).catch(() => {
      alert('Ocorreu um erro! Tente novamente. Se o erro persistir, contacte a Ume.')
    })
    mutate(request)
    setIsRunningPreChecks(false)
  }

  // TODO(etevaldo.melo): remove this piece of code once the budget flow
  // using the legacy endpoint.
  if (page == TransactionalCreditPages.Result && !data?.transactionalCreditEvaluation) {
    alert(unknownErrorMessage)
    setPage(TransactionalCreditPages.Form)
  }

  const renderPage = () => {
    switch (page) {
      case TransactionalCreditPages.Form:
        return <TransactionalCreditForm onSubmit={handleTxCreditEvaluationCreation} />
      case TransactionalCreditPages.Result:
        return <TransactionalCreditResult evaluationResult={data?.transactionalCreditEvaluation!!} />
    }
  }

  return (
    <Container>
      {renderPage()}
      {isLoading ||
        (isRunningPreChecks && (
          <Loading
            loadingText={
              isRunningPreChecks
                ? 'Aguarde, essa avaliação pode demorar um pouco. Permaneça na tela...'
                : 'Carregando...'
            }
          />
        ))}
    </Container>
  )
}

export default TransactionalCredit

const Container = styled(PhoneLayout).attrs({
  style: {
    justifyContent: 'space-between',
    padding: 40,
  },
})``
