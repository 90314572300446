import { getConfig } from '../../../config/config'

export default class SafeListService {
  static shouldUseNativeCameraSafeList(operatorId: string): Boolean {
    if (!operatorId) return false
    const operatorAccessList = getConfig().access_lists.useNativeCameraOperatorsSafeList

    if (!operatorAccessList) return false
    const regex = new RegExp(operatorAccessList)

    return Boolean(regex?.test(operatorId))
  }

  static shouldRenderNewPurchaseContractOptionsForRetailersSafeList(retailerId?: string): Boolean {
    if (!retailerId) return false
    const retailerAccessList = getConfig().access_lists.renderNewPurchaseContractOptionsForRetailersSafeList

    if (!retailerAccessList) return false
    const regex = new RegExp(retailerAccessList)

    return Boolean(regex?.test(retailerId))
  }
}
